.headerExpand {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.headerExpandText {
  font-size: 18px;
  margin-bottom: 5px;
}

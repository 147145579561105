.container {
  margin: 5px 10px 15px;
}
.header {
  background-color: white;
}
.content {
  background-color: white;
  margin-top: 10px;
  padding: 15px 20px;
}
.tab {
  /* margin-top: 10px; */
  margin: 15px 0px 10px 10px;
}
.expand {
  background-color: #f6f9ff;
  margin: 0px 10px;
}
.change_password {
  color: black;
  font-size: 14px;
  font-weight: 'bold';
}
.change_password:hover {
  color: #1890ff;
}
.input {
  height: 35px;
  border-radius: 5px;
}

.login {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.loginImage {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -5;
}

.containerLogin {
  max-width: 420px;
  min-width: 350px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 10px 10px 28px -9px rgba(0, 0, 0, 0.75);
  align-items: center;
  justify-content: center;
  padding: 50px;
  padding-bottom: 20px;
  padding-top: 0px;
  text-align: center;
}

/* css mới */
.limiter {
  width: 100%;
  margin: 0 auto;
}

.containerLogin100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  /* background: rgba(0, 0, 0, 0.1); */
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0.6) 100%
    ),
    url(../../assets/images/img_background_login2.jpg);
  background-size: cover;
}

.wrapLogin100 {
  width: 570px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

/*==================================================================
[ Title form ]*/
.login100FormTitle {
  width: 100%;
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(../../assets/images/img_background_login.jpg);
  padding: 70px 15px 74px 15px;
}

.login100FormTitle1 {
  font-family: Poppins-Bold;
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.2;
  text-align: center;
}

.login100FormTitle::before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(54, 84, 99, 0.7);
}

.contentLoginForm {
  width: 80%;
  margin: auto;
  margin-top: 40px;
}

/* .login_form_button {
  background-color: orange;
  border-color: orange;
  font-size: 16px;
  max-width: 200px;
  min-width: 150px;
  border-radius: 4px;
}

.login_form_button:hover {
  background-color: orange;
  border-color: orange;
  font-size: 16px;
  max-width: 200px;
  min-width: 150px;
  border-radius: 4px;
}

.login_form_button:focus {
  background-color: orange;
  border-color: orange;
  font-size: 16px;
  max-width: 200px;
  min-width: 150px;
  border-radius: 4px;
} */
